import React, { useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import CloseCircleSVG from '../shared/image-components/CloseCircleSVG'
import { LargeP, P, SmallP, Gotham } from '../shared/text'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  overflow: hidden;

  display: grid;

  border: 2px solid ${props => props.theme.colors.azure};

  width: 100%;
  grid-gap: 44px;
  padding: 25px 24px 26px 27px;
  grid-column: span 2;
  margin-bottom: 50px;

  ${media.small`
    grid-template-columns: 1fr;
    grid-row: ${props => props.idx % 2 === 0 ? props.idx + 2 : props.idx + 4};
  `}
  ${media.large`
    grid-template-columns: 295px 1fr;
    grid-row: 2;
  `}

  & p {
    color: ${props => props.theme.colors.azure};
  }

  & p,
  a {
    visibility: hidden;
  }
`

const TextBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(6, max-content) 1fr;
`

const ImageWrapper = styled.div`
  overflow: hidden;

  height: 100%;
  width: 100%;

  ${media.small`
    display: none;
  `}
  ${media.large`
    display: block;
  `}
`

const MarginSmallP = styled(SmallP)`
  ${media.small`
    margin-top: 16px;
  `}
  ${media.medium`
    margin-top: 20px;
  `}
`

const EmailAddress = styled(Gotham)`
  align-self: end;
  width: fit-content;

  color: ${props => props.theme.colors.healerite};
  text-transform: uppercase;

  padding-bottom: 3px;
  border-bottom: 1.5px solid ${props => props.theme.colors.white};
  margin-top: 30px;

  &:hover {
    border-bottom: 1.5px solid ${props => props.theme.colors.azure};
  }
`

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseIconWrapper = styled.div`
  cursor: pointer;

  & svg {
    ${media.small`
      height: 24px;
    `}
    ${media.medium`
      height: 36px;
      width: 36px;
    `}
  }

  &:hover {
    & svg {
      transition: 300ms ease transform;
      transform: rotate(90deg);

      & path {
        fill: ${props => props.theme.colors.healerite};
      }
      & circle {
        stroke: ${props => props.theme.colors.healerite};
      }
    }
  }
`

const EmployeeTitle = styled(LargeP)`
  line-height: 1.2;
`

const TitleP = styled(P)`
  max-width: 75%;
  line-height: 1.3 !important;
`

const EmployeeDetail = ({
  employee,
  idx,
  closeEmployeeCard,
}) => {
  const classNameRoot = employee.title
    .toLowerCase()
    .split(' ')
    .join('-')

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const tl = gsap.timeline({ delay: 0 })
    tl.from(`.employee-detail__container--${classNameRoot}`, {
      height: 0,
      width: 0,
      duration: 0.5,
    })
    tl.to(`.employee-detail__image--${classNameRoot}`, {
      autoAlpha: 1,
      duration: 0.5,
    })

    tl.to(
      `.employee-detail__copy--${classNameRoot}`,
      {
        autoAlpha: 1,
        duration: 0.5,
        stagger: 0.15,
      },
      '<'
    )
  }, [classNameRoot])

  const handleClose = () => {
    const tl = gsap.timeline()
    tl.to(`.employee-detail__container--${classNameRoot}`, {
      height: 0,
      opacity: 0,
      duration: 0.75,
    })
    tl.then(closeEmployeeCard)
  }

  const desktopCounter = idx % 3

  let desktopPositionKey = 'first'
  if (desktopCounter === 1) desktopPositionKey = 'second'
  if (desktopCounter === 2) desktopPositionKey = 'third'

  const mobileCounter = idx % 2
  let mobilePositionKey = 'first'
  if (mobileCounter === 1) mobilePositionKey = 'third'

  return (
    <Container
      id={`employee-detail--${classNameRoot}`}
      idx={idx}
      className={`employee-detail__container--${classNameRoot}`}
    >
      <ImageWrapper>
        <Img
          fluid={employee.headshot.childImageSharp.fluid}
          alt={employee.title}
          className={`employee-detail__image--${classNameRoot}`}
          style={{ visibility: 'hidden', height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </ImageWrapper>

      <TextBox>
        <FlexBox>
          <EmployeeTitle className={`employee-detail__copy--${classNameRoot}`}>
            <b>{employee.title}</b>
          </EmployeeTitle>
          <CloseIconWrapper
            onClick={handleClose}
            className={`employee-detail__copy--${classNameRoot}`}
          >
            <CloseCircleSVG />
          </CloseIconWrapper>
        </FlexBox>

        <TitleP className={`employee-detail__copy--${classNameRoot}`}>
          {employee.profTitle}
        </TitleP>

        <MarginSmallP className={`employee-detail__copy--${classNameRoot}`}>
          <b>In a nutshell: </b>
          {employee.bio}
        </MarginSmallP>

        <MarginSmallP className={`employee-detail__copy--${classNameRoot}`}>
          <b>Previously at: </b>
          {employee.previouslyAt}
        </MarginSmallP>
        <SmallP className={`employee-detail__copy--${classNameRoot}`}>
          <b>Geeks out over: </b>
          {employee.geeksOutOver}
        </SmallP>
        <SmallP className={`employee-detail__copy--${classNameRoot}`}>
          <b>Dream innovation: </b>
          {employee.dreamInnovation}
        </SmallP>

        <EmailAddress
          as="a"
          href={`mailto:${employee.email}`}
          className={`employee-detail__copy--${classNameRoot}`}
        >
          {employee.email}
        </EmailAddress>
      </TextBox>
    </Container>
  )
}

export default EmployeeDetail
