import React from 'react'
import styled from 'styled-components'

import AboveTheFold from './AboveTheFold'
import EmployeeGrid from './EmployeeGrid'
import WorkWithUs from './WorkWithUs'

const Main = styled.div`
  width: 100%;
`

const Team = ({ employees }) => {
  console.log(employees)
  return (
    <Main>
      <AboveTheFold />
      <EmployeeGrid employees={employees} />
      <WorkWithUs />
    </Main>
  )
}

export default Team
