import React from 'react'

const CloseCircleSVG = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="19" cy="19" r="18" stroke="#1325A9" stroke-width="2" />
    <g clip-path="url(#clip0)">
      <path
        d="M21.0575 18.5153L27.6769 11.8957C28.1077 11.4651 28.1077 10.7689 27.6769 10.3383C27.2464 9.90774 26.5502 9.90774 26.1196 10.3383L19.4999 16.958L12.8804 10.3383C12.4497 9.90774 11.7537 9.90774 11.3231 10.3383C10.8923 10.7689 10.8923 11.4651 11.3231 11.8957L17.9426 18.5153L11.3231 25.135C10.8923 25.5656 10.8923 26.2618 11.3231 26.6924C11.5377 26.9071 11.8198 27.015 12.1018 27.015C12.3837 27.015 12.6657 26.9071 12.8804 26.6924L19.4999 20.0727L26.1196 26.6924C26.3344 26.9071 26.6163 27.015 26.8983 27.015C27.1802 27.015 27.4622 26.9071 27.6769 26.6924C28.1077 26.2618 28.1077 25.5656 27.6769 25.135L21.0575 18.5153Z"
        fill="#1325A9"
      />
    </g>
    <defs>
      <clip-path id="clip0">
        <rect
          width="17"
          height="17"
          fill="white"
          transform="translate(11 10)"
        />
      </clip-path>
    </defs>
  </svg>
)

export default CloseCircleSVG
