import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { Button } from '../shared/buttons'
import { H1, P } from '../shared/text'
import { media } from '../utils/style-utils.js'

const Container = styled.div`

  border: 3px solid ${props => props.theme.colors.azure};
  border-radius: 15px;

  display: grid;
  place-items: center;
  grid-row-gap: 30px;

  color: ${props => props.theme.colors.azure};
  text-align: center;

  ${media.small`
    margin: 56px auto 71px auto;
    padding: 41px 20px 44px 20px;
    width: calc(100% - 60px);
  `}
  ${media.large`
    margin: 100px auto;
    padding: 33px 57px 48px 57px;
    max-width: 750px;
    width: 100%;
  `}

  & h1::after {
    display: none;
  }
`

const JoinUs = () => (
  <Container>
    <H1>Work with us</H1>
    <P>
      We're doing big things, and we're always looking for talented people to
      grow our team as we grow our brands.
    </P>
    <Button onClick={() => navigate('/careers')}>join us</Button>
  </Container>
)

export default JoinUs
