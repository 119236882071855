import React, { useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import EmployeeDetail from './EmployeeDetail'
import { LargeP, SmallP, Gotham } from '../shared/text'
import { media } from '../utils/style-utils.js'


const View = styled.span`
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: ${props => props.theme.colors.azure};
  font-family: Apercu, sans-serif;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.small`
    width: 50px;
    height: 50px;
    ${Gotham} {
      font-size: 60%;
    }
  `}

  ${media.large`
    ${Gotham} {
      font-size: 18px;
    }
    width: 107px;
    height: 107px;
  `}

`

const PaddedWrapper = styled.div`

  height: fit-content;
  width: 100%;

  border-radius: 3px;
  transition: 0.1s box-shadow ease-in;
  box-shadow: none;
`

const Grid = styled.div`
  position: relative;
  height: fit-content;
  display: grid;
  grid-row-gap: 10px;

  ${media.small`
    grid-row: ${props => props.idx % 2 === 0 ? props.idx + 1 : props.idx + 2};
  `}

  ${media.large`
    grid-row: unset;
  `}
  
`

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: grid;

  color: ${props => props.theme.colors.azure};
  grid-template-columns: 1fr;
  text-align: left;

  ${View} {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    ${View} {
      opacity: 1;
    }
  }

`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;

  max-height: 625px;
  width: 100%;

  transition: 200ms ease all;
  border-radius: 2px;
  cursor: pointer;
`

const EmployeeName = styled(LargeP)`
  visibility: hidden;

  font-family: Apercu, sans-serif;
  font-weight: 600;

  margin-top: 30px; // Offsets GSAP

  ${media.small`
    font-size: 18px;
    line-height: 1.5;
  `}
  ${media.large`
    text-align: left;
    font-size: 30px;
    line-height: 1.3;
  `}
`

const EmployeeTitle = styled(SmallP)`
  visibility: hidden;

  ${media.small`
    font-size: 12px;
    line-height: 1.4;
  `}
  ${media.large`
    font-size: 20px;
    line-height: 1.4;
    max-width: 85%;
  `}
`

const EmployeeCard = ({
  employee,
  activeEmployee,
  openEmployeeCard,
  closeEmployeeCard,
  idx,
}) => {
  const classNameRoot = employee.title
    ?.toLowerCase()
    ?.split(' ')
    ?.join('-')

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)

    const tl = gsap.timeline({
      delay: 0.25,
      scrollTrigger: `.${classNameRoot}__image`,
    })
    tl.to(`.${classNameRoot}__image`, { autoAlpha: 1, duration: 0.5 })

    gsap.to(
      `.${classNameRoot}__copy`,
      {
        scrollTrigger: `.${classNameRoot}__copy`,
        autoAlpha: 1,
        duration: 0.5,
        stagger: 0.2,
        y: -20,
        delay: 0.25,
      },
      '<'
    )
  }, [classNameRoot])

  const handleCardClick = () => {
    openEmployeeCard()

    if (window !== 'undefined') {
      gsap.to(window, {
        delay: 0.25,
        duration: 0.2,
        scrollTo: { y: `#employee-detail--${classNameRoot}`, offsetY: 200 },
      })
    }
  }

  return (
    <>
      <Grid activeEmployee={activeEmployee} onClick={handleCardClick} idx={idx}>
        <PaddedWrapper active={activeEmployee}>
          <Container className={`${classNameRoot}__container`}>
            <ImageWrapper>
              <Img
                fluid={employee.headshot.childImageSharp.fluid}
                alt={employee.title}
                className={`${classNameRoot}__image`}
                style={{ visibility: 'hidden', height: '100%', width: '100%' }}
                imgStyle={{ objectFit: 'cover' }}
              />
              <View>
                <Gotham>VIEW</Gotham>
              </View>
            </ImageWrapper>
            <div>
              <EmployeeName className={`${classNameRoot}__copy`}>
                {employee.title}
              </EmployeeName>
              <EmployeeTitle className={`${classNameRoot}__copy`}>
                {employee.profTitle}
              </EmployeeTitle>
            </div>
          </Container>
        </PaddedWrapper>
      </Grid>

      {activeEmployee && (
        <EmployeeDetail
          employee={employee}
          closeEmployeeCard={closeEmployeeCard}
          idx={idx}
        />
      )}

    </>
  )
}

export default EmployeeCard
