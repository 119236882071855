import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/shared/SEO'
import Layout from '../../components/Layout'
import Team from '../../components/Team'

const TeamPage = ({ location, data }) => {
  const employees = data.allMarkdownRemark.edges.map(
    edge => edge.node.frontmatter.employees
  )[0]

  return (
    <>
      <SEO title="The Brains Behind the Operation" description="Get to know our team of founders, operators, investors, marketers, and builders" />
      <Layout location={location}>
        <Team employees={employees} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query TeamPageQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { employees: { elemMatch: { hidden: { eq: false } } } }
      }
    ) {
      edges {
        node {
          frontmatter {
            employees {
              title
              profTitle
              company
              email
              bio
              previouslyAt
              geeksOutOver
              dreamInnovation
              headshot {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TeamPage
