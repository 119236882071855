import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Header from '../shared/Header'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  background-color: ${props => props.theme.colors.azure};
  width: 100%;
  display: grid;
  place-items: center;


  ${media.small`
    padding: 70px 20px 55px 20px;
  `}
  ${media.medium`
    padding: 100px 40px 55px 40px;
  `}
  ${media.large`
    padding: 90px 40px;
  `}
`

const query = graphql`
  query {
    image: file(relativePath: { regex: "/team/team-hero.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 1700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboveTheFold = () => {
  const { image } = useStaticQuery(query)

  const headerConfig = {
    header: 'The brains behind the operation',
    subheader:
      'Get to know our team of founders, operators, investors, marketers, and builders',
    image: { fluid: image.childImageSharp.fluid, alt: 'ID team members' },
    maxWidth: 934,
  }

  return (
    <Container>
      <Header config={headerConfig} />
    </Container>
  )
}

export default AboveTheFold
