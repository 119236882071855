import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import EmployeeCard from './EmployeeCard'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  display: grid;
  margin: 0 auto;
  
  ${media.small`
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${props => props.count * 2}, auto);
    grid-gap: 30px 0;
    padding: 41px 30px;
  `}
  ${media.medium`
    width: 660px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 41px 0px;
  `}
  ${media.large`
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 50px;
    max-width: 1100px;
    padding: 120px 0 0;
  `}
`

const EmployeeGrid = ({ employees }) => {
  const [activeEmployeeIdx, setActiveEmployeeIdx] = useState(null)

  const openEmployeeCard = idx => setActiveEmployeeIdx(idx)
  const closeEmployeeCard = () => setActiveEmployeeIdx(null)

  const containerRef = useRef(null)

  return (
    <Container ref={containerRef} count={employees.length}>
      {employees.map((employee, idx) => {
        return (
          <EmployeeCard
            employee={employee}
            activeEmployee={activeEmployeeIdx === idx}
            openEmployeeCard={() => openEmployeeCard(idx)}
            closeEmployeeCard={closeEmployeeCard}
            idx={idx}
            key={employee.title}
          />
        )
      })}
    </Container>
  )
}

export default EmployeeGrid
